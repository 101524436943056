<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <a href="" class="brand-logo">
                <img id="logo_img" v-cloak/>
            </a>
            <div class="card mb-0">
                <div class="card-body">
                    <h4 class="card-title mb-1 text-center">{{ $__('Sing up to our customer portal') }}</h4>
                    <p class="card-text mb-2 text-center">{{ $__('Please fill in your e-mail address.') }}</p>
                    <lang-chooser></lang-chooser>
                    <Form @submit="handleRegister" :validation-schema="schema" class="auth-login-form mt-2"
                          method="POST">
                        <div class="form-group">
                            <label for="login-email" class="form-label">{{ $__('Email') }}</label>
                            <Field type="text" class="form-control" id="login-email" name="email"
                                   placeholder="john@example.com" aria-describedby="login-email" tabindex="1" autofocus
                                   @focus="focus" @blur="blur"/>
                            <ErrorMessage name="email" class="error-feedback"/>
                        </div>
                        <div class="form-group">
                            <phone-select name="direct_number"
                                          type="text"
                                          :label="$__('Direct phone number')"
                                          :placeholder="$__('Direct phone number')"
                                          :success-message="$__('Phone valid')"
                            >
                                <template #label>
                                <span>
                                    {{ $__('General phone number') }}
                                </span>
                                </template>
                            </phone-select>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block" tabindex="4" :disabled="loading"
                                :style="{background: $env_color, borderColor: $env_color}">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span> {{ $__('Sign up') }}
                        </button>
                        <div class="form-group">
                            <div v-if="message" class="alert alert-danger" role="alert">
                                {{ message }}
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Form, Field, ErrorMessage} from "vee-validate";
import * as yup from "yup";
import "yup-phone-lite";

import {envColor} from "../mixins";
import phoneSelect from "@/components/utils/phoneSelect";
import langChooser from "@/components/utils/langChooser.vue";
import { setLocale } from 'yup';
export default {
    name: "Register",
    mixins: [envColor],
    components: {
        langChooser,
        Form,
        Field,
        ErrorMessage,
        phoneSelect,
    },
    data() {
        setLocale({
            mixed: {
                default: this.$__('required'),
                required: this.$__('required'),
            },
            string: {
                min: this.$__('must be at least ${min} characters'),
                required: this.$__('required'),
            },
        });

        const schema = yup.object().shape({
            email: yup.string().email().required().strict().lowercase(),
            direct_number: yup.string().min(6).required().phone(),
        });

        return {
            loading: false,
            message: "",
            schema,
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    created() {
        if (this.loggedIn) {
            this.$router.push("/");
        }
    },
    mounted() {
        document.body.classList.add('blank-page');
        const pathToBg = require(`@/assets/images/${this.$background_image}`)
        const pathToLogo = require(`@/assets/images/${this.$logo}`);
        document.body.style.backgroundImage = `url('${pathToBg}')`;
        document.body.style.backgroundRepeat = `no-repeat`;
        document.body.style.backgroundSize = `cover`;
        document.body.style.width = `100%`;
        document.body.style.top = `0`;
        document.body.style.left = `0`;
        let logo_img = document.getElementById('logo_img')
        logo_img.style.height = `100px`;
        logo_img.src = `${pathToLogo}`;
        let footer = document.getElementById('footer')
        footer.classList.add('footer_login')
    },
    unmounted() {
        document.body.classList.remove('blank-page');
        document.body.style.backgroundImage = 'none';
        let footer = document.getElementById('footer')
        footer.classList.remove('footer_login')
    },
    methods: {
        handleRegister(user) {
            this.loading = true;
            this.$store.dispatch("auth/register", user).then(
                () => {
                    this.$router.push("/register-finished");
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
};
</script>

<style scoped>

</style>