import axios from 'axios';

const API_URL = `${process.env.VUE_APP_ENV_API || 'http://127.0.0.1:8000/api/'}`;
// const API_URL = 'https://staging.wastedesk.io/api/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'token/', {
        email: user.email,
        password: user.password,
        scope: 'portal'
      })
      .then(response => {
        if (response.data.access) {
          localStorage.setItem('token', JSON.stringify(response.data));
        }

        return response.data;
      });
  }
  refresh(access, refresh) {
    return axios.post(
        API_URL + 'token/refresh/', {
          refresh: refresh
        }, {
          headers: {Authorization: `Token ${access}`}
        }
    )
  }
  logout() {
    localStorage.removeItem('token');
    return true
  }

  register(user) {
    return axios.post(API_URL + 'signup/', {
      email: user.email,
      direct_number: user.direct_number
    });
  }
  password_reset(user) {
    return axios.post(API_URL + 'portal/customer/password-reset/', {
      email: user.email,
      scope: 'portal',
    });
  }
  set_password(data) {
    return axios.post(API_URL + 'portal/customer/set-password/', data);
  }
}

export default new AuthService();