<template>
    <nav class="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow">
        <div class="navbar-container d-flex content">
            <div class="navbar-collapse" id="navbar-mobile">
                <div class="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                    <ul class="nav navbar-nav">
                        <li class="nav-item mobile-menu d-xl-none mr-auto">
                            <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#">
                                <i class="pi pi-bars"></i>
                            </a>
                        </li>
                    </ul>
                    <ul class="nav navbar-nav bookmark-icons">
                        <li class="nav-item d-flex align-items-center flex-row">
                            <a class="nav-link" href="#" data-toggle="tooltip" data-placement="top" :title="title">
                                <b>{{ title }}</b>
                            </a>
                        </li>
                        <li class="nav-item d-none d-lg-block">
                            <a class="nav-link" href="#" data-toggle="tooltip" data-placement="top" title="'Calendar'">
                                <i class="ficon feather icon-calendar"></i>
                            </a>
                        </li>
                    </ul>
                    <span id="breadcrumb"></span>
                </div>
                <ul class="nav navbar-nav align-items-center ml-auto">
                    <MultiSelect :showToggleAll="true"
                                 v-model="selectedRelations"
                                 :options="user.relations"
                                 style="max-width: 100%"
                                 optionLabel="name" placeholder="Environment switcher">
                        <template #option="slotProps">
                            <div class="p-multiselect-car-option" v-if="slotProps.option.billing_address">
                                <span>{{slotProps.option.name}} {{slotProps.option.billing_address.street_name}} {{slotProps.option.billing_address.house_number}}{{slotProps.option.billing_address.housse_number_addition}}</span>
                            </div>
                            <div class="p-multiselect-car-option" v-else-if="slotProps.option.visiting_address">
                                <span>{{slotProps.option.name}} {{slotProps.option.visiting_address.street_name}} {{slotProps.option.visiting_address.house_number}}{{slotProps.option.visiting_address.housse_number_addition}}</span>
                            </div>
                            <div class="p-multiselect-car-option" v-else>
                                <span>{{slotProps.option.name}}</span>
                            </div>
                        </template>
                    </MultiSelect>
                    <li class="nav-item dropdown dropdown-language" id="set-language">
                        <a class="nav-link dropdown-toggle" id="dropdown-flag" href="javascript:void(0);"
                           data-toggle="dropdown"
                           aria-haspopup="true" aria-expanded="false">
                            <i :class="'flag-icon flag-icon-' + authUserLang.code"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-flag">
                            <a class="dropdown-item" v-for="lang in available_langs" :key="lang.code"
                               @click="setUserLang(lang)"><i :class="'flag-icon flag-icon-' + lang.code"></i>
                                {{ lang.name }}</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown dropdown-user">
                        <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user"
                           href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div class="user-nav d-sm-flex d-none">
                                <span class="user-name font-weight-bolder">{{ user.full_name }}</span>
                                <span class="user-status">{{ $__('Customer') }}</span>
                            </div>
                            <span class="avatar">
                  <img class="round" :src="user.photo ? user.photo: blank_user_avatar" alt="avatar"
                       height="40" width="40">
                  <span class="avatar-status-online"></span>
                </span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-user">
                            <a class="dropdown-item" @click="handleLogout"><i data-feather="log-out"></i>
                                {{ $__('Logout') }}</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {ref} from "vue";
import MultiSelect from 'primevue/multiselect';


export default {
    name: "TopNavbar",
    props: ['title'],
    components: {
        MultiSelect
    },
    setup() {
        const loading = ref(true);
        const selectedRelations = ref([]);
        const blank_user_avatar = ref(require(`@/assets/images/blank_user_avatar.png`))
        return {loading, blank_user_avatar, selectedRelations}
    },
    watch: {
        selectedRelations: {
            handler: function (newVal) {
                newVal.sort(function (a, b){
                    return a.id - b.id;
                })
                this.addRelation2SelectedList(newVal)
            }
        },
        user: {
            handler: function (newVal) {
                let relations = JSON.parse(localStorage.getItem('relations'));
                if (relations && relations.length > 0){
                    newVal.relations.forEach(relation => {
                        if (relations.some(r => r.id === relation.id)){
                            this.selectedRelations.push(relation)
                        }
                    })
                } else {
                    this.selectedRelations.push(newVal.relations[0])
                }
                this.selectedRelations.sort(function (a, b){
                    return a.id - b.id;
                })
                this.addRelation2SelectedList(this.selectedRelations)
                this.loading = false
            }
        },
        'user.lang': {
            handler: function (newVal) {
                if (newVal) {
                    this.$forceUpdate()
                }
            }
        }
    },
    mounted() {
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
            available_langs: state => state.auth.available_langs,
            authUserLang: state => state.auth.user.lang
        }),
    },
    methods: {
        showDropdown() {
            this.$refs['searchInput'].$refs['filterInput'].style.setProperty('--bcolor', this.$env_color, 'important');
        },
        handleLogout() {
            this.$store.dispatch("auth/logout").then(() => {
                this.$router.push('/login')
            });
        },
        ...mapActions({
            addRelation2SelectedList: 'orders/addRelation2SelectedList',
            setUserLang: 'auth/setUserLang'
        }),
    }
}
</script>

<style scoped>

</style>
