<template>
    <div class="card card-custom card-shadow border-secondary flex-fill">
        <loading :active="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
        <h6 class="card-header">{{ $__('Last Orders') }}</h6>
        <div class="card-body" :class="collection_orders.length === 0 ? 'd-flex no-result': ''">
            <div class="row h-100">
                <div class="col-12">
                    <div class="table-responsive" v-if="collection_orders.length > 0">
                        <table class="table table-striped table-hover table-borderless">
                            <tr>
                                <th>{{ $__('Date') }}</th>
                                <th>{{ $__('Order') }}</th>
                                <th>{{ $__('Status') }}</th>
                            </tr>
                            <tr v-for="collection_order in collection_orders" :key="collection_order.id">
                                <td>{{ collection_order.created_at }}</td>
                                <td><a href="javascript:void(0)" @click="downloadTransportLetter(collection_order)">{{ collection_order.order_number }}</a></td>
                                <td>{{ collection_order.status.value }}</td>
                            </tr>
                        </table>
                    </div>
                    <span v-else class="no-result-text">{{ $__('No results') }}</span>
                </div>
                <div class="col-12 mt-auto">
                    <router-link class="btn btn-success waves-effect float-right" to="/orders"
                             :style="{background: $env_color}">{{ $__('All orders') }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import agent from "@/agent";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import {ref} from 'vue';

export default {
    name: "LastOrdersCard",
    setup() {
        const isLoading = ref(false);
        const fullPage = ref(false);
        const collection_orders = ref([]);
        return {isLoading, fullPage, collection_orders}
    },
    components: {
        Loading
    },
    props: {
        modelValue: {
            type: [Object],
        },
    },
    watch: {
        'modelValue.id': {
            handler: function () {
                this.getLastOrders()
            }
        },
        'authUserLang': {
            handler: function (newVal) {
                if (newVal && this.modelValue.id){
                    this.getLastOrders()
                }
            }
        },
    },
    computed: {
        ...mapState({
            authUserLang: state => state.auth.user.lang
        }),
    },
    created() {
    },
    mounted() {
        if (this.modelValue.id) {
            this.getLastOrders()
        }
    },
    methods: {
        getLastOrders() {
            let self = this;
            this.isLoading = true
            agent.Orders.last(this.modelValue.id).then(response => {
                self.collection_orders = response.data
                this.isLoading = false
            }).catch(error => {
                this.$toast.add({
                    severity: 'error',
                    summary: error.response.statusText,
                    detail: error.response.data.detail,
                    life: 5000
                });
                this.isLoading = false
            })
        },
        downloadTransportLetter(order){
            let self = this;
            agent.Orders.getTransportLetter(order.id, order.order_type).then(response => {
                let blob = new Blob([response.data], { type: 'application/pdf' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = order.order_number + '-' + self.$__('transport-letter') + '.pdf'
                link.click()

            }).catch(error => {
                this.$toast.add({
                    severity: 'error',
                    summary: 'response' in error ? error.response.statusText: 'Error',
                    detail: 'response' in error ? error.response.data.detail: error,
                    life: 5000
                });
                this.isLoading = false
            })
        }
    },
}
</script>

<style scoped>

.table th, .table td {
    padding: 0.72rem 1rem;
    vertical-align: middle;
}
</style>