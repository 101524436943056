<template>
    <div class="row">
        <div class="col-md-6">
            <div class="card card-custom card-shadow border-secondary flex-fill">
                <h6 class="card-header">{{ $__('Generate report(s)') }}</h6>
                <div class="card-body">
                    <form method="POST">
                        <div class="form-group">
                            <label for="eural-codes" class="form-label">{{ $__('Select eural codes') }}</label>
                            <Dropdown :showClear="true" @change="selectEuralCode"
                                      id="eural-codes" v-model="report.eural_code"
                                      :style="`--bcolor: ${$env_color};width: 100%`"
                                      :options="eural_codes.filter(item => !report.selectedEuralCodes.includes(item))"
                                      optionLabel="full_code" :placeholder="$__('Select eural codes')">
                                <template #value="slotProps">
                                    <div class="p-dropdown-car-value" v-if="slotProps.value">
                                        <span>{{
                                                slotProps.value.waste_groups.length > 0 ? slotProps.value.waste_groups[0].name + ' | ' + slotProps.value.full_code + ' | ' + slotProps.value.description : slotProps.value.full_code + ' | ' + slotProps.value.description
                                            }}</span>
                                    </div>
                                    <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                                </template>
                                <template #option="slotProps">
                                    <div class="p-dropdown-car-option">
                                        <span>{{
                                                slotProps.option.waste_groups.length > 0 ? slotProps.option.waste_groups[0].name + ' | ' + slotProps.option.full_code + ' | ' + slotProps.option.description : slotProps.option.full_code + ' | ' + slotProps.option.description
                                            }}</span>
                                    </div>
                                </template>
                            </Dropdown>
                        </div>
                        <div class="form-group">
                            <label class="form-label">{{ $__('Choosen eural codes') }}</label>
                            <template v-for="eural_code in report.selectedEuralCodes" :key="eural_code.id">
                                <div class="alert alert-primary alert-dismissible fade show" role="alert">
                                    <div class="alert-body">
                                        {{
                                            eural_code.waste_groups.length > 0 ? eural_code.waste_groups[0].name + ' | ' + eural_code.full_code + ' | ' + eural_code.description : eural_code.full_code + ' | ' + eural_code.description
                                        }}
                                    </div>
                                    <button type="button" class="close" aria-label="Close"
                                            @click.prevent="unSelectEuralCode(eural_code)">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                            </template>
                        </div>
                        <div class="form-group">
                            <label class="form-label">{{ $__('Select date range') }}</label>
                            <div class="row">
                                <div class="col-md-6">
                                    <Calendar v-model="report.start_date"
                                              showIcon
                                              iconDisplay="input"
                                              style="width: 100%"
                                              showButtonBar
                                              placeholder="dd/mm/yy"
                                              dateFormat="dd/mm/yy"
                                              :key="index"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <Calendar v-model="report.end_date"
                                              showIcon
                                              iconDisplay="input"
                                              style="width: 100%"
                                              showButtonBar
                                              placeholder="dd/mm/yy"
                                              dateFormat="dd/mm/yy"
                                              :key="index"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-label">{{ $__('Select report format') }}</label>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="customRadio1" v-model="report.page_format" :value="1"
                                               name="customRadio" class="custom-control-input" checked=""
                                               :style="`--bcolor: ${$env_color}`">
                                        <label class="custom-control-label" for="customRadio1"
                                               :style="`--bcolor: ${$env_color}`">{{ $__('1 page per eural code') }}</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="customRadio2" v-model="report.page_format" :value="2"
                                               name="customRadio" class="custom-control-input"
                                               :style="`--bcolor: ${$env_color}`">
                                        <label class="custom-control-label" for="customRadio2"
                                               :style="`--bcolor: ${$env_color}`">{{ $__('Grouped') }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="custom-control custom-checkbox mb-1">
                                <input type="checkbox" class="custom-control-input" id="customCheck1"
                                       v-model="report.is_other_email">
                                <label class="custom-control-label" for="customCheck1"
                                       :style="`--bcolor: ${$env_color}`">{{ $__('Other email address') }}</label>
                            </div>
                            <label v-show="report.is_other_email" for="other-email"
                                   :class=" + v$.report.other_email.$invalid ? 'form-label text-danger': 'form-label'">{{ $__('Email') }}
                                {{ v$.report.other_email.$invalid ? ' - ' + v$.report.other_email.email.$message : '' }}</label>
                            <input id="other-email" v-show="report.is_other_email" v-model="report.other_email"
                                   type="email" class="form-control"
                                   :placeholder="$__('Please fill in the other email address')" @focus="focus"
                                   @blur="blur">
                        </div>
                        <div class="d-flex align-content-center">
                            <button type="button" :disabled="!canProcess" @click="handleSendEmail"
                                    class="btn btn-primary" :style="{background: $env_color, borderColor: $env_color}">
                                {{ $__('Send per e-mail') }}
                            </button>
                            <button type="button" :disabled="!canProcess" @click="handleDownload"
                                    class="btn btn-primary waves-effect ml-auto"
                                    :style="{background: $env_color, borderColor: $env_color}">{{ $__('Download') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Dropdown from "primevue/dropdown";
import Calendar from 'primevue/calendar';
import {mapState} from "vuex";
import useVuelidate from "@vuelidate/core";
import {email} from "@vuelidate/validators";
import agent from "@/agent";
import {envColor} from "../../mixins";
const feather = require('feather-icons');

export default {
    name: "ReportsPage",
    setup() {
        return {v$: useVuelidate()};
    },
    mixins: [envColor],
    components: {
        Dropdown, Calendar
    },
    data() {
        return {
            report: {
                eural_code: null,
                selectedEuralCodes: [],
                start_date: null,
                end_date: null,
                is_other_email: false,
                other_email: '',
                page_format: 1,
                relation_ids: []
            },
            eural_codes: [],
            index: 1
        }
    },
    watch: {
        'selectedRelations': {
            handler: function () {
                this.report = {
                    eural_code: null,
                    selectedEuralCodes: [],
                    start_date: null,
                    end_date: null,
                    is_other_email: false,
                    other_email: '',
                    page_format: 1,
                    relation_ids: []
                }
                this.getEuralCodes()
            },
            deep: true
        },
        'user.lang.code': {
            handler: function () {
                this.changeLocale()
            },
        }
    },
    computed: {
        ...mapState({
            selectedRelations: state => state.orders.selectedRelations,
            user: state => state.auth.user,
        }),
        canProcess: function () {
            return this.report.start_date !== null && this.report.end_date !== null && this.report.selectedEuralCodes.length > 0
        }
    },
    validations() {
        return {
            report: {
                other_email: {email}
            }
        }
    },
    created() {
    },
    mounted() {
        feather.replace()
        this.getEuralCodes()
        this.changeLocale()
    },
    methods: {
        changeLocale() {
            this.$primevue.config.locale.today = this.$__('Today');
            this.$primevue.config.locale.clear = this.$__("Clear")
            this.$primevue.config.locale.firstDayOfWeek = 1
            this.$primevue.config.locale.dayNamesMin = [
                this.$__('Su'),
                this.$__('Mo'),
                this.$__('Tu'),
                this.$__('We'),
                this.$__('Th'),
                this.$__('Fr'),
                this.$__('Sa')
            ]
            this.$primevue.config.locale.monthNamesShort = [
                this.$__('Jan'),
                this.$__('Feb'),
                this.$__('Mar'),
                this.$__('Apr'),
                this.$__('May'),
                this.$__('Jun'),
                this.$__('Jul'),
                this.$__('Aug'),
                this.$__('Sep'),
                this.$__('Oct'),
                this.$__('Nov'),
                this.$__('Dec')
            ]
            this.index += 1
        },
        getEuralCodes() {
            let self = this;

            this.report.relation_ids = this.selectedRelations.map(function (item) {
                return item['id'];
            })
            agent.Relation.getEuralCodesByRelation(this.report.relation_ids).then(response => {
                self.eural_codes = response.data
            }).catch(error => {
                self.$toast.add({
                    severity: 'error',
                    summary: error.response.statusText,
                    detail: error.response.data.detail,
                    life: 5000
                });
            })
        },
        selectEuralCode(event) {
            if (event.value) {
                if (!this.report.selectedEuralCodes.find(x => x.id === event.value.id)) {
                    this.report.selectedEuralCodes.push(event.value)
                }
            }
            this.report.eural_code = null
        },
        unSelectEuralCode(eural_code) {
            this.report.selectedEuralCodes = this.report.selectedEuralCodes.filter(x => x.id !== eural_code.id)
        },
        handleSendEmail() {
            let report = {...this.report}
            report.start_date = moment(report.start_date).format('YYYY-MM-DD')
            report.end_date = moment(report.end_date).format('YYYY-MM-DD')
            agent.Report.send_email(report).then(response => {
                if(response.status === 200){
                    this.$toast.add({
                        severity: 'success',
                        summary: response.statusText,
                        detail: response.data.detail,
                        life: 3000
                    });
                } else if (response.status === 204){
                    this.$toast.add({
                        severity: 'error',
                        summary: response.statusText,
                        detail: this.$__('There is no information for the report generation'),
                        life: 3000
                    });
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: response.status,
                        detail: response.statusText,
                        life: 3000
                    });
                }
            }).catch(error => {
                this.$toast.add({
                    severity: 'error',
                    summary: error.response.statusText,
                    detail: error.response.data.detail,
                    life: 5000
                });
            })
        },
        handleDownload() {
            let report = {...this.report}
            report.start_date = moment(report.start_date).format('YYYY-MM-DD')
            report.end_date = moment(report.end_date).format('YYYY-MM-DD')
            agent.Report.download(report).then(response => {
                if(response.status === 200){
                    const blob = new Blob([response.data], {
                        type: "application/pdf",
                    });
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = `report-(${report.start_date} - ${report.end_date}).pdf`
                    link.click()
                    URL.revokeObjectURL(link.href)
                } else if (response.status === 204){
                    this.$toast.add({
                        severity: 'error',
                        summary: response.statusText,
                        detail: this.$__('There is no information for the report generation'),
                        life: 3000
                    });
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: response.status,
                        detail: response.statusText,
                        life: 3000
                    });
                }
            }).catch(error => {
                this.$toast.add({
                    severity: 'error',
                    summary: error.response.statusText,
                    detail: error.response.data.detail,
                    life: 5000
                });
            })
        },
    }
}
</script>

<style scoped>

</style>