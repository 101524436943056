import { createStore, createLogger } from "vuex";
import { auth } from "./auth.module";
import { orders } from "./orders.module";

const store = createStore({
  namespaced: true,
  modules: {
    auth,
    orders,
  },
  plugins: [createLogger()],
});

export default store;