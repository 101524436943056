import agent from "@/agent"

const initialState = {
    waste_groups: [],
    eural_codes: [],

    selectedRelations: [],
    collections_history: [],
}
export const orders = {
    namespaced: true,
    state: initialState,
    getters: {
        wasteGroups: state => {
            return state.waste_groups;
        },
        euralCodes: state => {
            return state.eural_codes;
        },
        collectionsHistory: state => {
            return state.collections_history;
        },
        selectedRelations: state => {
            return state.selectedRelations;
        }
    },
    actions: {
        addRelation2SelectedList({ commit }, relations){
            commit('setSelectedRelations', relations)
            return Promise.resolve(relations);
        },
        getWasteGroups({ commit }, relation_id) {
            return agent.Orders.getWasteGroups(relation_id).then(function (response){
                commit('getWasteGroups', response.data)
                return Promise.resolve(response.data);
            }).catch(error => {
                commit('getWasteGroupsFailure')
                return Promise.reject(error);
            });
        },
        getEuralCodesByWasteGroup({ commit }, payload) {
            return agent.Orders.getEuralCodesByWasteGroup(
                payload.waste_group_id, payload.relation_id
            ).then(function (response){
                commit('getEuralCodes', response.data)
                return Promise.resolve(response.data);
            }).catch(error => {
                commit('getEuralCodesFailure')
                return Promise.reject(error);
            });
        },
        getEuralCodes({ commit }) {
            return agent.Orders.getEuralCodes().then(function (response){
                commit('getEuralCodes', response.data)
                return Promise.resolve(response.data);
            }).catch(error => {
                commit('getEuralCodesFailure')
                return Promise.reject(error);
            });
        },
        clearWasteGroup({ commit }) {
            commit('clearWasteGroup')
        },
        clearEuralCodes({ commit }) {
            commit('clearEuralCodes')
        },
        clearCollectionHistory({ commit }) {
            commit('clearCollectionHistory')
        },
        getCollectionHistory({ commit }, payload) {
            return agent.Orders.getCollectionHistory(payload.waste_group_id, payload.origin_id, payload.eural_code_id).then(function (response){
                commit('getCollectionHistory', response.data)
                return Promise.resolve(response.data);
            }).catch(error => {
                commit('getCollectionHistoryFailure')
                return Promise.reject(error);
            });
        },
    },
    mutations: {
        getWasteGroups(state, data) {
            state.waste_groups = data;
        },
        getWasteGroupsFailure(state) {
            state.waste_groups = initialState.waste_groups;
        },
        clearWasteGroup(state){
            state.waste_groups = []
        },
        getEuralCodes(state, data) {
            state.eural_codes = data;
        },
        clearEuralCodes(state){
            state.eural_codes = []
        },
        getEuralCodesFailure(state) {
            state.eural_codes = initialState.eural_codes;
        },
        getCollectionHistory(state, data) {
            state.collections_history = data;
        },
        clearCollectionHistory(state) {
            state.collections_history = [];
        },
        getCollectionHistoryFailure(state) {
            state.collections_history = initialState.collections_history;
        },
        setSelectedRelations(state, relations) {
            state.selectedRelations = relations;
            localStorage.setItem('relations', JSON.stringify(relations));
        },
    }
};