<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <a href="" class="brand-logo">
                <img id="logo_img" v-cloak/>
            </a>
            <div class="card mb-0">
                <div class="card-body">
                    <Form @submit="handlePasswordReset" :validation-schema="schema" class="auth-login-form mt-2"
                          method="POST" v-if="!success">
                        <h4>{{ $__('Create new password') }}</h4>
                        <div class="form-group">
                            <label for="password">{{ $__('Password') }}</label>
                            <div class="input-group input-group-merge form-password-toggle">
                                <Field ref="password" :type="passwordShowen ? 'text': 'password'" @focus="focus_group"
                                       @blur="blur_group" class="form-control form-control-merge" id="password"
                                       name="password" tabindex="2"
                                       placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                       aria-describedby="password"/>
                                <div class="input-group-append" @click="showPassword">
                                    <span class="input-group-text cursor-pointer" :data-visible="passwordShowen"
                                          v-show="passwordShowen">
                                        <i data-feather="eye-off"></i>
                                    </span>
                                    <span class="input-group-text cursor-pointer" :data-visible="!passwordShowen"
                                          v-show="!passwordShowen">
                                        <i data-feather="eye"></i>
                                    </span>
                                </div>
                            </div>
                            <ErrorMessage name="password" class="error-feedback"/>
                        </div>
                        <div class="form-group">
                            <label for="password2">{{ $__('Repeat password') }}</label>
                            <div class="input-group input-group-merge form-password-toggle">
                                <Field :type="password2Showen ? 'text': 'password'" @focus="focus_group"
                                       @blur="blur_group" class="form-control form-control-merge" id="password2"
                                       name="password2" tabindex="2"
                                       placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                       aria-describedby="password2"/>
                                <div class="input-group-append" @click="showPassword2">
                                    <span class="input-group-text cursor-pointer" :data-visible="password2Showen"
                                          v-show="password2Showen">
                                        <i data-feather="eye-off"></i>
                                    </span>
                                                    <span class="input-group-text cursor-pointer" :data-visible="!password2Showen"
                                                          v-show="!password2Showen">
                                        <i data-feather="eye"></i>
                                    </span>
                                </div>
                            </div>
                            <ErrorMessage name="password2" class="error-feedback"/>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block" tabindex="4" :disabled="isLoading"
                                :style="{background: $env_color, borderColor: $env_color}">
                            <span v-show="isLoading"
                                  class="spinner-border spinner-border-sm"></span>{{ $__('Sign up') }}
                        </button>
                        <div class="form-group">
                            <div v-if="message" class="alert alert-danger" role="alert">
                                {{ message }}
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Form, Field, ErrorMessage} from "vee-validate";
import {envColor, languageCheck} from "../mixins";
import * as yup from "yup";

const feather = require('feather-icons')

export default {
    name: "SetPassword",
    mixins: [envColor, languageCheck],
    components: {Form, Field, ErrorMessage,},
    data() {
        const schema = yup.object().shape({
            password: yup.string().min(6).required(this.$__("Password is required!")),
            password2: yup.string().min(6).required(this.$__("Password is required!")).oneOf([yup.ref('password')], this.$__('Passwords does not match')),
        });
        return {
            message: "",
            passwordShowen: false,
            password2Showen: false,
            isLoading: false,
            uid: '',
            token: '',
            scope: '',
            email: '',
            schema
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    created() {
        if (this.loggedIn) {
            this.$router.push("/");
        }
    },
    mounted() {
        feather.replace()
        document.body.classList.add('blank-page');
        const pathToBg = require(`@/assets/images/${this.$background_image}`)
        const pathToLogo = require(`@/assets/images/${this.$logo}`);
        document.body.style.backgroundImage = `url('${pathToBg}')`;
        document.body.style.backgroundRepeat = `no-repeat`;
        document.body.style.backgroundSize = `cover`;
        document.body.style.width = `100%`;
        document.body.style.top = `0`;
        document.body.style.left = `0`;
        let logo_img = document.getElementById('logo_img')
        logo_img.style.height = `100px`;
        logo_img.src = `${pathToLogo}`;
        let footer = document.getElementById('footer')
        footer.classList.add('footer_login')
        const urlParams = new URLSearchParams(location.search);
        this.uid = urlParams.get('uid')
        this.token = urlParams.get('token')
        this.scope = urlParams.get('scope')
        this.email = urlParams.get('email')
    },
    unmounted() {
        document.body.classList.remove('blank-page');
        document.body.style.backgroundImage = 'none';
        let footer = document.getElementById('footer')
        footer.classList.remove('footer_login')
    },
    methods: {
        handlePasswordReset(password) {
            this.loading = true;
            let data = {
                uid: this.uid,
                token: this.token,
                scope: this.scope,
                email: this.email,
                password: password.password,
                password2: password.password2
            }
            this.$store.dispatch("auth/set_password", data).then(
                () => {
                    this.$router.push("/set-password-finished");
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        showPassword() {
            this.passwordShowen = !this.passwordShowen
        },
        showPassword2() {
            this.password2Showen = !this.password2Showen
        },
    },
};
</script>

<style scoped>

</style>