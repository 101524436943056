<template>
    <div class="card card-shadow flex-fill" v-cloak>
        <loading :active="isLoading" :can-cancel="false" :is-full-page="false">
        </loading>
        <div style="width: 100%; background-color: #E4E6E7; border-top-right-radius: 5.5px; border-top-left-radius: 5.5px;">
            <img v-show="!isLoading" v-if="account_manager"
                 class="card-img-top card-img-fix"
                 :src="account_manager && account_manager.avatar ? main_url + account_manager.avatar.file: blank_user_avatar"
                 alt="Card image cap">
        </div>
        <div class="card-body" v-show="!isLoading">
            <div class="row h-100">
                <div class="col-12">
                    <template v-if="account_manager">
                        <h4 class="card-title">{{ $__('Your account manager') }}</h4>
                        <p class="card-text">
                            {{$__('Hi')}} {{ user.full_name }}
                        </p>
                        <p class="card-text">
                            {{
                                $__('My name is') + ' ' + account_manager.full_name + ' ' + $__('and i am your account manager. If anything don`t hesitate to contact me')
                            }}
                        </p>
                    </template>
                    <template v-else>
                        <div class="d-flex no-result h-100">
                            <h4 class="card-title no-result-text">{{ $__('No account manager') }}</h4>
                        </div>
                    </template>
                </div>
                <div class="col-12 mt-auto">
                    <div class="d-flex w-100">
                        <a v-show="false" href="javascript:void(0)" class="btn btn-success waves-effect first-button"
                           :style="{background: $env_color}">{{ $__('Call me back') }}</a>
                        <a v-if="account_manager.phone" :href="'tel:' + account_manager.phone"
                           class="btn btn-success waves-effect ml-auto"
                           :style="{background: $env_color}">{{ $__('Call') }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ref} from "vue";
import agent from '@/agent'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import {isEmpty} from 'lodash';

export default {
    name: "AccountManagerCard",
    setup() {
        const account_manager = ref({});
        const isLoading = ref(false);
        const blank_user_avatar = ref(require(`@/assets/images/blank_user_avatar.png`))
        return {account_manager, isLoading, blank_user_avatar}
    },
    props: {
        modelValue: {
            type: [Object],
        },
    },
    components: {
        Loading
    },
    watch: {
        'modelValue.id': {
            handler: function () {
                this.getRelationDetail()
            }
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            main_url: 'auth/main_url',
        })
    },
    mounted() {
        if (!isEmpty(this.modelValue)) {
            this.getRelationDetail()
        }
    },
    methods: {
        getRelationDetail() {
            let self = this;
            this.isLoading = true
            agent.Relation.get(this.modelValue.id).then(function (response) {
                self.account_manager = response.data.account_manager
                self.isLoading = false
            })
        },
    },
}
</script>

<style scoped>

</style>