import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import CollectionOrders from "./components/collection_orders/CollectionOrdersPage";
import InvoicesPage from "./components/invoices/InvoicesPage";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
import RegisterFinished from "./components/RegisterFinished.vue";
import PasswordReset from "./components/PasswordReset.vue";
import PasswordResetFinished from "./components/PasswordResetFinished.vue";
import SetPassword from "./components/SetPassword.vue";
import SetPasswordSuccess from "./components/SetPasswordSuccess.vue";
import store from "@/store";
import ReportsPageCard from "./components/reports/ReportsPageCard";
import StatisticsPage from "./components/statistics/StatisticsPage";
import Activate from "./components/Activate";
const Profile = () => import("./components/Profile.vue")

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/orders",
    name: "Collection Orders",
    component: CollectionOrders,
    meta: { requiresAuth: true },
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: InvoicesPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/reports",
    name: "Reports",
    component: ReportsPageCard,
    meta: { requiresAuth: true },
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: StatisticsPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: InvoicesPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/register-finished",
    component: RegisterFinished,
  },
  {
    path: "/activate",
    component: Activate,
  },
  {
    path: "/password-reset",
    component: PasswordReset,
  },
  {
    path: "/set-password",
    component: SetPassword,
  },
  {
    path: "/set-password-finished",
    component: SetPasswordSuccess,
  },
  {
    path: "/password-reset-finished",
    component: PasswordResetFinished,
  },
  {
    path: "/profile",
    name: "profile",
    meta: { requiresAuth: true },
    component: Profile,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.auth.status.loggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router;