<template>
    <div class="TextInput" :class="{ 'has-error': !!errorMessage, success: meta.valid }">

        <label :for="name">{{ label }}</label>
        <vue-tel-input v-model="phone" mode="international" :name="name" :defaultCountry="currentCountry"
                       :id="name"
                       :type="type"
                       :value="inputValue" :inputOptions="inputOptions"
                       :placeholder="placeholder"
                       @input="handleChange"
                       @blur="handleBlur"></vue-tel-input>
        <p class="help-message" v-show="errorMessage || meta.valid">
            {{ errorMessage || successMessage }}
        </p>
    </div>
</template>

<script>
import { VueTelInput } from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css'
import { useField } from "vee-validate";


export default {
    name: "phoneSelect",
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        is_required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "text",
        },
        value: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        successMessage: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
    },
    setup(props) {
        const {
            value: inputValue,
            errorMessage,
            handleBlur,
            handleChange,
            meta,
        } = useField(props.name, undefined, {
            initialValue: props.value,
        });
        return {
            handleChange,
            handleBlur,
            errorMessage,
            inputValue,
            meta,
        };
    },
    data(){
        return {
            inputErrors: {
                phone: '',
            },
            currentCountry: process.env.VUE_APP_ENV_CURRENT_COUNTRY,
            inputOptions: {
                autocomplete:"on",
                autofocus:false,
                id:"",
                maxlength:25,
                name:"telephone",
                placeholder: this.placeholder,
                readonly:false,
                required:false,
                styleClasses:"",
                tabindex:0,
                type:"tel",
            },
        }
    },
    components: {
        VueTelInput,
    },
    computed: {
        phone: {
            get(){
                return this.modelValue
            },
            set(modelValue){
                this.$emit('input', modelValue)
            }
        }
    },
    methods: {
        validate (number, phoneObject) {
            this.inputErrors.phone = ''
            if (phoneObject.valid !== undefined) {
                if (this.is_required) {
                    if(number === ''){
                        this.inputErrors.phone = this.$__('Required')
                    }
                }
                if (phoneObject.valid === false) {
                    this.inputErrors.phone = this.$__('Not valid phone number')
                }
            }
        }
    },
}
</script>

<style scoped>

</style>