import $ from "jquery";
import {mapActions} from "vuex";

const envColor = {
    mounted(){
        this.change_page_color()
    },
    methods: {
        focus(event){
            event.target.style.borderColor = this.$env_color;
        },
        blur(event){
            event.target.style.removeProperty('border-color')
        },
        focus_group(event){
            let next_element = event.target.nextSibling.querySelector('span[data-visible=true]')
            event.target.style.borderColor = this.$env_color;
            next_element.style.borderColor = this.$env_color;
        },
        blur_group(event){
            let next_element = event.target.nextSibling.querySelector('span[data-visible=true]')
            event.target.style.removeProperty('border-color')
            next_element.style.removeProperty('border-color')
        },
        change_page_color(){
            let self = this;
            $('.pace-progress').css('background', self.$env_color)
        }
    }
}
const languageCheck = {
    data() {
        return {
            browserLanguage: '',
            browser2UserLanguage: '',
        };
    },
    computed: {
        availableLangs() {
            return this.$store.state.auth.available_langs;
        },
        currentUserLang() {
            return this.$store.state.auth.user.lang;
        }
    },
    mounted() {
        let browserLang = navigator.language || navigator.userLanguage;
        browserLang = browserLang.split('-')[0]
        this.browserLanguage = browserLang
        if (browserLang === 'en'){
          browserLang = 'us'
        }
        if(this.currentUserLang.code !== browserLang){
            browserLang = this.currentUserLang.code
        }
        let userLang = this.availableLangs.find(el => el.code === browserLang)
        this.browser2UserLanguage = userLang
        if (userLang){
            this.setUserLang(userLang)
        }
    },
    methods: {
        ...mapActions({
            setUserLang: 'auth/setUserLang'
        }),
    }
}
export {
    envColor, languageCheck
}