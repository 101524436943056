<template>
    <div class="card card-custom card-shadow border-secondary flex-fill">
        <loading :active="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
        <h6 class="card-header">{{ $__('Last Invoices') }}</h6>
        <div class="card-body" :class="invoices.length === 0 ? 'd-flex no-result': ''">
            <div class="row h-100">
                <div class="col-12">
                    <div class="table-responsive" v-if="invoices.length > 0">
                        <table class="table table-striped table-hover table-borderless">
                            <tr>
                                <th>{{ $__('Date') }}</th>
                                <th>{{ $__('Invoice') }}</th>
                                <th>{{ $__('Status') }}</th>
                            </tr>
                            <tr v-for="invoice in invoices" :key="invoice.id">
                                <td>{{ invoice.created_at }}</td>
                                <td><a href="javascript:void(0)" @click="downloadInvoicePDF(invoice)">{{ invoice.invoice_number }}</a></td>
                                <td>{{ invoice.status.value }}</td>
                            </tr>
                        </table>
                    </div>
                    <span v-else class="no-result-text">{{ $__('No results') }}</span>
                </div>
                <div class="col-12 mt-auto">
                    <router-link class="btn btn-success waves-effect float-right" to="/invoices"
                             :style="{background: $env_color}">{{ $__('All invoices') }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref} from "vue";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/css/index.css';
import agent from "@/agent";
import {mapState} from "vuex";

export default {
    name: "LastInvoicesCard",
    setup() {
        const isLoading = ref(false);
        const fullPage = ref(false);
        const invoices = ref([]);
        return {isLoading, fullPage, invoices}
    },
    components: {
        Loading
    },
    props: {
        modelValue: {
            type: [Object],
        },
    },
    watch: {
        'modelValue.id': {
            handler: function () {
                this.getLastInvoices()
            }
        },
        'authUserLang': {
            handler: function (newVal) {
                if (newVal && this.modelValue.id){
                    this.getLastInvoices()
                }
            }
        },
    },
    computed: {
        ...mapState({
            authUserLang: state => state.auth.user.lang
        }),
    },
    mounted() {
        if (this.modelValue.id) {
            this.getLastInvoices()
        }
    },
    methods: {
        getLastInvoices() {
            let self = this;
            this.isLoading = true
            agent.Invoice.last(this.modelValue.id).then(response => {
                self.invoices = response.data
                this.isLoading = false
            }).catch(error => {
                this.$toast.add({
                    severity: 'error',
                    summary: error.response.statusText,
                    detail: error.response.data.detail,
                    life: 5000
                });
                this.isLoading = false
            })
        },
        downloadInvoicePDF(invoice){
            agent.Invoice.getPDF(invoice.id).then(response => {
                let blob = new Blob([response.data], { type: 'application/pdf' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = invoice.invoice_number + '.pdf'
                link.click()

            }).catch(error => {
                this.$toast.add({
                    severity: 'error',
                    summary: error.response.statusText,
                    detail: error.response.data.detail,
                    life: 5000
                });
                this.isLoading = false
            })
        }
    },
}
</script>

<style scoped>

.table th, .table td {
    padding: 0.72rem 1rem;
    vertical-align: middle;
}
</style>