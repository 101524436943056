<template>
    <div class="row">
        <div class="col-md-12">
            <loading :active="isLoading" :can-cancel="false" :is-full-page="false"></loading>
            <div class="card">
                <div class="card-body">
                    <DataTable :totalRecords="totalRecords" :lazy="true" :value="invoices" :paginator="true" :rows="10"
                               v-model:filters="filters1"
                               paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                               :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll" dataKey="id"
                               ref="invoice_datatable"
                               :currentPageReportTemplate="$__('Showing {first} to {last} of {totalRecords}')"
                               @page="onPage($event)"
                               @sort="onSort($event)" @filter="onFilter($event)"
                               :globalFilterFields="['invoice_number']"
                    >
                        <template #header>
                            <div class="d-flex">
                 <span class="p-input-icon-left ml-auto">
                      <i class="pi pi-search"/>
                      <InputText @keydown.enter="filterCallback()" v-model="filters1['global'].value"
                                 :placeholder="$__('Global Search')" :style="`--bcolor: ${$env_color}`"/>
                  </span>
                            </div>
                        </template>
                        <Column field="invoice_number" :header="$__('Invoice')" :sortable="true"></Column>
                        <Column field="created_at" :header="$__('Created')"></Column>
                        <Column field="expiry_date" :header="$__('Due date')"></Column>
                        <Column field="orders" :header="$__('Order number')">
                            <template #body="slotProps">
                                <div v-tooltip.top="slotProps.data.orders.map(o => o.order_number).join(', ')">
                                    {{slotProps.data.orders.slice(0, 3).map(o => o.order_number).join(', ')}} {{slotProps.data.orders.length > 3 ? '+' + slotProps.data.orders.slice(3).length: ''}}
                                </div>
                            </template>
                        </Column>
                        <Column field="disposers" :header="$__('Disposer')">
                            <template #body="slotProps">
                                <div v-tooltip.top="slotProps.data.orders.map(o => o.disposer.name).join(', ')">
                                    {{slotProps.data.orders.slice(0, 3).map(o => o.disposer.name).join(', ')}} {{slotProps.data.orders.length > 3 ? '+' + slotProps.data.orders.slice(3).length: ''}}
                                </div>
                            </template>
                        </Column>
                        <Column field="total_inc_vat" :header="$__('Amount')"></Column>
                        <Column field="type" :header="$__('Type')"></Column>
                        <Column field="status" :header="$__('Status')" :sortable="true" filterMatchMode="equals">
                            <template #body="slotProps">
                                <span class="badge"
                                      :class="slotProps.data.status.key === 3 ? 'badge-success' : slotProps.data.status.key === 2 ? 'badge-primary' : slotProps.data.status.key === 4 ? 'badge-danger': 'badge-warning'">{{ slotProps.data.status.value }}</span>
                            </template>
                        </Column>
                        <Column :header="$__('Actions')" :sortable="false">
                            <template #body="slotProps">
                                <Button icon="pi pi-download" class="p-button-rounded p-button-secondary" @click="downloadInvoicePDF(slotProps.data)" />
                            </template>
                        </Column>
                        <template #empty>
                            {{ $__('No invoices found.') }}
                        </template>
                        <template #paginatorLeft>
                            <Button type="button" @click="refreshInvoices" icon="pi pi-refresh" class="p-button-text"/>
                        </template>
                        <template #paginatorRight>
                            <Button type="button" icon="pi pi-cloud" class="p-button-text" v-show="false"/>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import {ref} from 'vue';
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Loading from "vue-loading-overlay";
import {mapState} from "vuex";
import agent from "@/agent";
import {envColor} from "../../mixins";
import Tooltip from 'primevue/tooltip';

require('primevue/resources/themes/saga-blue/theme.css')
require('primevue/resources/primevue.min.css')
require('primeicons/primeicons.css')


export default {
    name: "InvoicesPage",
    mixins: [envColor],
    setup() {
        const search = ''
        const isLoading = ref(true);
        const filters1 = ref({'global': {value: null, matchMode: 'icontains'}});
        const invoices = ref([]);
        const totalRecords = ref(0);
        const lazyParams = ref({});
        return {search, isLoading, filters1, invoices, totalRecords, lazyParams};
    },
    components: {
        DataTable, Column, Button, InputText, Loading
    },
    directives: {
        'tooltip': Tooltip
    },
    computed: {
        ...mapState({
            selectedRelations: state => state.orders.selectedRelations,
            authUserLang: state => state.auth.user.lang
        }),
    },
    watch: {
        'selectedRelations': {
            handler: function () {
                let self = this;
                self.getInvoices()
            },
            deep: true
        },
        'authUserLang': {
            handler: function (newVal) {
                if (newVal){
                    this.getInvoices()
                }
            }
        },
    },
    mounted() {
        this.lazyParams = {
            first: 0,
            rows: this.$refs.invoice_datatable.rows,
            sortField: null,
            sortOrder: null,
            filters: this.filters1
        };
        this.getInvoices()
    },
    methods: {
        getInvoices() {
            let self = this;
            this.isLoading = true

            let ids = this.selectedRelations.map(function(item) {
                return item['id'];
            });

            agent.Invoice.all(ids, {lazyEvent: this.lazyParams}).then(response => {
                self.invoices = response.data['invoices']
                self.totalRecords = response.data['totalRecords']
                this.isLoading = false
            })
        },
        refreshInvoices() {
            let self = this;
            this.isLoading = true

            let ids = this.selectedRelations.map(function(item) {
                return item['id'];
            });

            agent.Invoice.all(ids, {lazyEvent: this.lazyParams}).then(response => {
                self.invoices = response.data['invoices']
                self.totalRecords = response.data['totalRecords']
                this.isLoading = false
            })
        },
        onSort(event) {
            this.lazyParams = event;
            this.getInvoices();
        },
        onPage(event) {
            this.lazyParams = event;
            this.getInvoices();
        },
        filterCallback() {
            this.lazyParams.filters = this.filters1;
            this.getInvoices();
        },
        onFilter() {
            this.lazyParams.filters = this.filters1;
            this.getInvoices();
        },
        downloadInvoicePDF(invoice){
            agent.Invoice.getPDF(invoice.id).then(response => {
                let blob = new Blob([response.data], { type: 'application/pdf' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = invoice.invoice_number + '.pdf'
                link.click()

            }).catch(error => {
                this.$toast.add({
                    severity: 'error',
                    summary: error.response.statusText,
                    detail: error.response.data.detail,
                    life: 5000
                });
                this.isLoading = false
            })
        }
    },
}
</script>

<style scoped>

</style>