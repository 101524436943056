<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <a href="" class="brand-logo">
                <img id="logo_img" v-cloak />
            </a>
            <loading :active="isLoading" :can-cancel="false" :is-full-page="true"></loading>
            <div class="card mb-0" v-if="!isLoading">
                <div class="card-body">
                    <Form @submit="activateContact" :validation-schema="schema" class="auth-login-form mt-2" method="POST" v-if="!success">
                        <h4>{{$__('Create new password')}}</h4>
                        <div class="form-group">
                            <label for="password">{{$__('Password')}}</label>
                            <div class="input-group input-group-merge form-password-toggle">
                                <Field ref="password" :type="passwordShowen ? 'text': 'password'" @focus="focus_group" @blur="blur_group" class="form-control form-control-merge" id="password" name="password" tabindex="2" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" aria-describedby="password" />
                                <div class="input-group-append" @click="showPassword">
                            <span class="input-group-text cursor-pointer" :data-visible="passwordShowen" v-show="passwordShowen">
                                <i data-feather="eye-off" ></i>
                            </span>
                                    <span class="input-group-text cursor-pointer" :data-visible="!passwordShowen" v-show="!passwordShowen">
                                <i data-feather="eye"></i>
                            </span>
                                </div>
                            </div>
                            <ErrorMessage name="password" class="error-feedback" />
                        </div>
                        <div class="form-group">
                            <label for="password2">{{$__('Repeat password')}}</label>
                            <div class="input-group input-group-merge form-password-toggle">
                                <Field :type="password2Showen ? 'text': 'password'" @focus="focus_group" @blur="blur_group" class="form-control form-control-merge" id="password2" name="password2" tabindex="2" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" aria-describedby="password2" />
                                <div class="input-group-append" @click="showPassword2">
                            <span class="input-group-text cursor-pointer" :data-visible="password2Showen" v-show="password2Showen">
                                <i data-feather="eye-off" ></i>
                            </span>
                                    <span class="input-group-text cursor-pointer" :data-visible="!password2Showen" v-show="!password2Showen">
                                <i data-feather="eye"></i>
                            </span>
                                </div>
                            </div>
                            <ErrorMessage name="password2" class="error-feedback" />
                        </div>
                        <button type="submit" class="btn btn-primary btn-block" tabindex="4" :disabled="isLoading" :style="{background: $env_color, borderColor: $env_color}">
                            <span v-show="isLoading" class="spinner-border spinner-border-sm"></span>{{$__('Sign up')}}
                        </button>
                        <div class="form-group">
                            <div v-if="message" class="alert alert-danger" role="alert">
                                {{ message }}
                            </div>
                        </div>
                    </form>
                    <div v-else-if="success == 1">
                        <h4 class="card-title mb-1 text-center">{{$__('Activation successful')}} </h4>
                        <p class="text-center mt-2">
                            <span>&#8592;</span>
                            <router-link to="/login">
                                <span>{{$__('Back to login')}}</span>
                            </router-link>
                        </p>
                    </div>
                    <div v-else-if="success == 2">
                        <h4 class="card-title mb-1 text-center">{{$__('Your account already created')}} </h4>
                        <p class="text-center mt-2">
                            <span>&#8592;</span>
                            <router-link to="/login">
                                <span>{{$__('Back to login')}}</span>
                            </router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import agent from "@/agent";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import { Form, Field, ErrorMessage } from "vee-validate";
import {envColor, languageCheck} from "../mixins";
import * as yup from "yup";
const feather = require('feather-icons')

export default {
    name: "Activate",
    components:{
        Loading, Form, Field, ErrorMessage,
    },
    mixins: [envColor, languageCheck],
    data() {
        const schema = yup.object().shape({
            password: yup.string().min(6).required(this.$__("Password is required!")),
            password2: yup.string().min(6).required(this.$__("Password is required!")).oneOf([yup.ref('password')], this.$__('Passwords does not match')),
        });
        return {
            success: 0,
            message: "",
            uid: '',
            token: '',
            scope: '',
            email: '',
            isLoading: false,
            passwordShowen: false,
            password2Showen: false,
            schema
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    created() {
        if (this.loggedIn) {
            this.$router.push("/");
        }
    },
    mounted () {
        feather.replace()
        document.body.classList.add('blank-page');
        const pathToBg = require(`@/assets/images/${this.$background_image}`)
        const pathToLogo = require(`@/assets/images/${this.$logo}`);
        document.body.style.backgroundImage = `url('${pathToBg}')`;
        document.body.style.backgroundRepeat = `no-repeat`;
        document.body.style.backgroundSize = `cover`;
        document.body.style.width = `100%`;
        document.body.style.top = `0`;
        document.body.style.left = `0`;
        let logo_img = document.getElementById('logo_img')
        logo_img.style.height = `100px`;
        logo_img.src = `${pathToLogo}`;
        let footer = document.getElementById('footer')
        footer.classList.add('footer_login')
        const urlParams = new URLSearchParams(location.search);
        this.uid = urlParams.get('uid')
        this.token = urlParams.get('token')
        this.scope = urlParams.get('scope')
        this.email = urlParams.get('email')
        this.getUserInfo()
    },
    unmounted () {
        document.body.classList.remove('blank-page');
        document.body.style.backgroundImage = 'none';
        let footer = document.getElementById('footer')
        footer.classList.remove('footer_login')
    },
    methods: {
        activateContact: function (password){
            if(this.uid && this.token && this.scope){
                let data = {
                    uid: this.uid,
                    token: this.token,
                    scope: this.scope,
                    email: this.email,
                    password: password.password
                }
                this.isLoading = true
                // eslint-disable-next-line no-unused-vars
                agent.UserService.activateUser(data).then(response => {
                    this.isLoading = false
                    this.success = 1
                }).catch(error => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.isLoading = false
                })
            }
        },
        showPassword(){
            this.passwordShowen = !this.passwordShowen
        },
        showPassword2(){
            this.password2Showen = !this.password2Showen
        },
        getUserInfo(){
            // eslint-disable-next-line no-unused-vars
            agent.UserService.getUserInfo(this.email).then(response => {
                this.success = response.data.success
                // eslint-disable-next-line no-unused-vars
            }).catch(error => {
                this.success = 2
            })
        }
    },
};
</script>

<style scoped>

</style>