<template>
  <div class="row">
    <div class="col-md-12">
      <Chart></Chart>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/statistics/Chart";
import {envColor} from "../../mixins";

export default {
  name: "StatisticsPage",
  mixins: [envColor],
  components: {
    Chart
  },
}
</script>

<style scoped>
</style>