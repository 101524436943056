import translations from "./translations";
import store from "../store";

export default {
    install: (app) => {
        app.config.globalProperties.$__ = key => {
            if(key in translations && store.state.auth.user.lang.code in translations[key]){
                return translations[key][store.state.auth.user.lang.code];
            }
            return key;
        }
    }
};