<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <a href="" class="brand-logo">
                <img id="logo_img" v-cloak/>
            </a>
            <div class="card mb-0">
                <div class="card-body">
                    <h4 class="card-title mb-1 text-center">{{ $__('Sign up succesfull if') }} </h4>
                    <p class="card-text mb-2 text-center">
                        {{ $__('your e-mail address is known in our system and you are granted access to our customer portal, you should recieve an e-mail with further instructions.') }}
                    </p>
                    <p class="card-text mb-2 text-center">
                        {{ $__('If your e-mail address is not known of our representatives will contact you by phone.') }}
                    </p>
                    <p class="text-center mt-2">
                        <span>&#8592;</span>
                        <router-link to="/login">
                            <span>{{ $__('Back to login') }}</span>
                        </router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Register",
    components: {},
    data() {
        return {
            message: "",
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    created() {
        if (this.loggedIn) {
            this.$router.push("/");
        }
    },
    mounted() {
        document.body.classList.add('blank-page');
        const pathToBg = require(`@/assets/images/${this.$background_image}`)
        const pathToLogo = require(`@/assets/images/${this.$logo}`);
        document.body.style.backgroundImage = `url('${pathToBg}')`;
        document.body.style.backgroundRepeat = `no-repeat`;
        document.body.style.backgroundSize = `cover`;
        document.body.style.width = `100%`;
        document.body.style.top = `0`;
        document.body.style.left = `0`;
        let logo_img = document.getElementById('logo_img')
        logo_img.style.height = `100px`;
        logo_img.src = `${pathToLogo}`;
        let footer = document.getElementById('footer')
        footer.classList.add('footer_login')
    },
    unmounted() {
        document.body.classList.remove('blank-page');
        document.body.style.backgroundImage = 'none';
        let footer = document.getElementById('footer')
        footer.classList.remove('footer_login')
    },
    methods: {},
};
</script>

<style scoped>

</style>