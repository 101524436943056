import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
// import './registerServiceWorker'
import LoadScript from "vue-plugin-load-script";
import __ from "./translations/translate"
import VueNumberFormat from 'vue-number-format'
require('@/assets/css/cards.css')
require('@/assets/css/custom.css')

const app = createApp(App);
app.config.globalProperties.$env_color = process.env.VUE_APP_ENV_BUTTON_COLOR
app.config.globalProperties.$favicon = process.env.VUE_APP_ENV_BUTTON_COLOR
app.config.globalProperties.$background_image = process.env.VUE_APP_ENV_MAIN_BG || 'wubben_bg.jpg'
app.config.globalProperties.$logo = process.env.VUE_APP_ENV_MAIN_LOGO || 'wubben_logo.png'
app.config.globalProperties.$logo_small = process.env.VUE_APP_ENV_MAIN_LOGO_SMALL || 'wubben_favicon.ico'
app.use(LoadScript);
app.use(router);
app.use(store);
app.use(ToastService);
app.use(PrimeVue);
app.use(__);
app.use(VueNumberFormat, {prefix: '', decimal: ',', thounsand: '.'})
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  app,
  dsn: "https://c08d844fdea549449fcc68c9180b43f0@glitchtip.sedna.software/5",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", process.env.VUE_APP_ENV_TRACING_ORIGIN, /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

app.mount('#app')