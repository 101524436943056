<template>
    <loading :active="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-6">
                    <Dropdown @change="getChartData" v-model="waste_group" :options="waste_groups"
                              :placeholder="$__('Select waste group')" optionLabel="name" optionValue="id"
                              :style="`--bcolor: ${$env_color}`"/>
                </div>
                <div class="col-6 text-right">
                    <Dropdown @change="getChartData" v-model="previous_year" :options="years"
                              :style="`--bcolor: ${$env_color}`"/>
                    vs.
                    <Dropdown @change="getChartData" v-model="current_year" :options="years"
                              :style="`--bcolor: ${$env_color}`"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="chart" ref="chartdiv">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <div class="card card-custom card-shadow border-secondary flex-fill">
                <h6 class="card-header">{{ $__('Total collected') }}</h6>
                <div class="card-body">
                    <div class="row" v-if="total_data && total_data.total_collected">
                        <div class="col-6">{{ total_data.total_collected.collected_weight_current_year.year }}</div>
                        <div class="col-6 text-right">
                            {{vueNumberFormat(total_data.total_collected.collected_weight_current_year.value, {suffix: ' kg'})}}
                        </div>
                        <div class="col-6">
                            {{ total_data.total_collected.collected_weight_previous_year.year }}
                        </div>
                        <div class="col-6 text-right">
                            {{vueNumberFormat(total_data.total_collected.collected_weight_previous_year.value, {suffix: ' kg'})}}
                        </div>
                        <div class="col-6">{{ $__('Difference') }}</div>
                        <div class="col-6 text-right"
                             :class="total_data.total_collected.difference < 0 ? 'text-red': 'text-green'">
                            {{vueNumberFormat(total_data.total_collected.difference, {suffix: '%', decimal: '.', thousand: '', precision: 2,})}}
                        </div>
                    </div>
                    <span v-else class="d-flex justify-content-center">{{ $__('No data') }}</span>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="card card-custom card-shadow border-secondary flex-fill">
                <h6 class="card-header">{{ $__('Amount of orders') }}</h6>
                <div class="card-body">
                    <div class="row" v-if="total_data && total_data.amount_of_orders">
                        <div class="col-6">{{ total_data.amount_of_orders.collected_amount_current_year.year }}</div>
                        <div class="col-6 text-right">
                            {{ total_data.amount_of_orders.collected_amount_current_year.value }}
                        </div>
                        <div class="col-6">{{ total_data.amount_of_orders.collected_amount_previous_year.year }}</div>
                        <div class="col-6 text-right">
                            {{ total_data.amount_of_orders.collected_amount_previous_year.value }}
                        </div>
                        <div class="col-6">{{ $__('Difference') }}</div>
                        <div class="col-6 text-right"
                             :class="total_data.amount_of_orders.difference < 0 ? 'text-red': 'text-green'">
                            {{vueNumberFormat(total_data.amount_of_orders.difference, {suffix: '%', decimal: '.', thousand: '', precision: 2,})}}
                        </div>
                    </div>
                    <span v-else class="d-flex justify-content-center">{{ $__('No data') }}</span>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="card card-custom card-shadow border-secondary flex-fill">
                <h6 class="card-header">{{ $__('Average weight per order') }}</h6>
                <div class="card-body">
                    <div class="row" v-if="total_data && total_data.average">
                        <div class="col-6">{{ total_data.average.collected_average_current_year.year }}</div>
                        <div class="col-6 text-right">
                            {{vueNumberFormat(total_data.average.collected_average_current_year.value, {suffix: ' kg'})}}
                        </div>
                        <div class="col-6">{{ total_data.average.collected_average_previous_year.year }}</div>
                        <div class="col-6 text-right">
                            {{vueNumberFormat(total_data.average.collected_average_previous_year.value, {suffix: ' kg'})}}
                        </div>
                        <div class="col-6">{{ $__('Difference') }}</div>
                        <div class="col-6 text-right"
                             :class="total_data.average.difference < 0 ? 'text-red': 'text-green'">
                            {{vueNumberFormat(total_data.average.difference, {suffix: '%', decimal: '.', thousand: '', precision: 2,})}}
                        </div>
                    </div>
                    <span v-else class="d-flex justify-content-center">{{ $__('No data') }}</span>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="card card-custom card-shadow border-secondary flex-fill">
                <h6 class="card-header">{{ $__('Co2 savings (used oil)') }}</h6>
                <div class="card-body">
                    <div class="row" v-if="total_data && total_data.co2waste">
                        <div class="col-6">{{ total_data.co2waste.co2_current_year.year }}</div>
                        <div class="col-6 text-right">
                            {{vueNumberFormat(total_data.co2waste.co2_current_year.value, {suffix: ' kg'})}}
                        </div>
                        <div class="col-6">{{ total_data.co2waste.co2_previous_year.year }}</div>
                        <div class="col-6 text-right">
                            {{vueNumberFormat(total_data.co2waste.co2_previous_year.value, {suffix: ' kg'})}}
                        </div>
                        <div class="col-6">{{ $__('Difference') }}</div>
                        <div class="col-6 text-right"
                             :class="total_data.co2waste.difference < 0 ? 'text-green': 'text-red'">
                            {{vueNumberFormat(total_data.co2waste.difference, {suffix: '%', decimal: '.', thousand: '', precision: 2,})}}
                        </div>
                    </div>
                    <span v-else
                          class="d-flex justify-content-center">{{ $__('Not applicable for this waste group') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref} from 'vue';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_en_US from "@amcharts/amcharts4/lang/en_US";
import am4lang_nl_NL from "@amcharts/amcharts4/lang/nl_NL";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
import {mapActions, mapGetters} from "vuex";
import Dropdown from "primevue/dropdown";
import agent from "@/agent";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
    name: "Chart",
    components: {
        Dropdown, Loading
    },
    setup() {
        const chart = ref();
        const previous_year = ref(new Date().getFullYear() - 1);
        const current_year = ref(new Date().getFullYear());
        const years = ref([]);
        const waste_group = ref();
        const chart_data = ref()
        const total_data = ref()
        const isLoading = ref(false);
        const fullPage = ref(false);
        return {
            chart, previous_year, current_year, years, waste_group, chart_data, total_data,
            isLoading, fullPage
        };
    },
    computed: {
        ...mapGetters({
            selectedRelations: 'orders/selectedRelations',
            waste_groups: 'orders/wasteGroups',
            user: 'auth/user',
        })
    },
    watch: {
        'user.lang': {
            handler: function (newVal, oldVal) {
                if (newVal && newVal !== oldVal && this.chart) {
                    this.getChartData()
                }
            }
        },
        selectedRelations: {
            handler: function (newVal) {
                if (newVal) {
                    this.getChartData()
                }
            },
            deep: true
        }
    },
    mounted() {
        this.getWasteGroups()
        let max = new Date().getFullYear()
        let min = max - 10
        for (let i = max; i >= min; i--) {
            this.years.push(i)
        }
        this.getChartData()
    },
    methods: {
        ...mapActions({
            getWasteGroups: 'orders/getWasteGroups',
        }),
        getChartData() {
            let self = this;
            this.isLoading = true
            if (this.current_year < this.previous_year) {
                this.current_year = this.previous_year + 1
            }
            if (this.previous_year >= this.current_year) {
                this.previous_year = this.current_year - 1
            }

            let ids = this.selectedRelations.map(function (item) {
                return item['id'];
            });

            agent.Orders.getChartData(
                ids, this.waste_group, this.previous_year, this.current_year
            ).then(response => {
                self.chart_data = response.data
                self.drawChart();
                let el = document.querySelector('.chart');
                let matches = el.querySelectorAll('[shape-rendering="auto"]');
                this.isLoading = false
                matches.forEach(item => {
                    item.remove()
                })
            }).catch(error => {
                console.log(error)
            })
            agent.Orders.getTotal(
                ids, this.waste_group, this.previous_year, this.current_year
            ).then(response => {
                self.total_data = response.data
            }).catch(error => {
              console.log(error)
            })
        },
        drawChart() {
            if (this.chart) {
                am4core.disposeAllCharts();
            }

            let chart = am4core.create(
                this.$refs.chartdiv, am4charts.XYChart,
            );
            if (this.user.lang.code === 'nl') {
                chart.language.locale = am4lang_nl_NL;
            } else {
                chart.language.locale = am4lang_en_US;
            }
            chart.paddingBottom = 50;
            chart.cursor = new am4charts.XYCursor();
            chart.scrollbarX = new am4core.Scrollbar();

            let colors = {};
            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "category";
            categoryAxis.renderer.minGridDistance = 1;
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.dataItems.template.text = `${this.$__('{realName}')}`;
            categoryAxis.adapter.add("tooltipText", function () {
                return categoryAxis.tooltipDataItem.dataContext.realName;
            })

            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.tooltip.disabled = true;
            valueAxis.min = 0;

            let columnSeries = chart.series.push(new am4charts.ColumnSeries());
            columnSeries.columns.template.width = am4core.percent(80);
            columnSeries.tooltipText = "{provider}: {realName}, kg: {valueY}" + `, ${this.$__('Orders')}: {valueX}{valueZ}`;
            columnSeries.dataFields.categoryX = "category";
            columnSeries.dataFields.valueY = "value";
            columnSeries.dataFields.valueX = "history_collection_orders"
            columnSeries.dataFields.valueZ = "current_collection_orders"

            columnSeries.columns.template.adapter.add("fill", function (fill, target) {
                let name = target.dataItem.dataContext.realName;
                if (!colors[name]) {
                    colors[name] = chart.colors.next();
                }
                target.stroke = colors[name];
                return colors[name];
            })


            let rangeTemplate = categoryAxis.axisRanges.template;
            rangeTemplate.tick.disabled = false;
            rangeTemplate.tick.location = 0;
            rangeTemplate.tick.strokeOpacity = 0.6;
            rangeTemplate.tick.length = 60;
            rangeTemplate.grid.strokeOpacity = 0.5;
            rangeTemplate.label.tooltip = new am4core.Tooltip();
            rangeTemplate.label.tooltip.dy = -10;
            rangeTemplate.label.cloneTooltip = false;


            let chartData = [];
            for (let providerName in this.chart_data) {
                let providerData = this.chart_data[providerName];
                let tempArray = [];
                for (let itemName in providerData) {
                    if (itemName !== "history_collection_orders" && itemName !== "current_collection_orders") {
                        // count++;
                        tempArray.push({
                            category: providerName + "_" + itemName,
                            realName: itemName,
                            value: providerData[itemName],
                            provider: providerName
                        })
                    }
                }
                // add quantity and count to middle data item (line series uses it)
                let lineSeriesDataIndex = 0
                tempArray[lineSeriesDataIndex].history_collection_orders = providerData.history_collection_orders;
                // tempArray[lineSeriesDataIndex].count = count;

                let lineSeriesDataIndex2 = 1;
                tempArray[lineSeriesDataIndex2].current_collection_orders = providerData.current_collection_orders;
                // tempArray[lineSeriesDataIndex2].count = count;

                am4core.array.each(tempArray, function (item) {
                    chartData.push(item);
                })
                let range = categoryAxis.axisRanges.create();
                range.category = tempArray[0].category;
                range.endCategory = tempArray[tempArray.length - 1].category;
                range.label.text = tempArray[0].provider;
                range.label.dy = 30;
                range.label.truncate = true;
                range.label.fontWeight = "bold";
                range.label.tooltipText = tempArray[0].provider;

                range.label.adapter.add("maxWidth", function (maxWidth, target) {
                    let range = target.dataItem;
                    let startPosition = categoryAxis.categoryToPosition(range.category, 0);
                    let endPosition = categoryAxis.categoryToPosition(range.endCategory, 1);
                    let startX = categoryAxis.positionToCoordinate(startPosition);
                    let endX = categoryAxis.positionToCoordinate(endPosition);
                    return endX - startX;
                })
            }
            chart.data = chartData
            let range = categoryAxis.axisRanges.create();
            range.category = chart.data[chart.data.length - 1].category;
            range.label.disabled = true;
            range.tick.location = 1;
            range.grid.location = 1;
            this.chart = chart
        },
    },
    beforeUnmount() {
        am4core.disposeAllCharts();
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 500px;
}

.text-red {
    color: red;
}

.text-green {
    color: #BBCD6E;
}
</style>