<template>
    <div class="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true" ref="mainMenu" id="mainMenu">
        <div class="navbar-header">
            <ul class="nav navbar-nav flex-row">
                <li class="nav-item mr-auto">
                    <router-link to="/" class="navbar-brand">
                        <img id="logo_img" :src="logo_src" height="35"/>
                    </router-link>
                </li>
                <li class="nav-item nav-toggle"><a :style="`--tcolor: ${$env_color};`"
                                                   class="nav-link modern-nav-toggle pr-0 env_color"
                                                   data-toggle="collapse" @click="toggleMenu"><i
                    class="d-block d-xl-none text-primary toggle-icon font-medium-4" data-feather="x"></i><i
                    class="d-none d-xl-block collapse-toggle-icon font-medium-4  text-primary" data-feather="disc"
                    data-ticon="disc"></i></a></li>
            </ul>
        </div>
        <div class="shadow-bottom"></div>
        <div class="main-menu-content">
            <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
                <li class="nav-item" :class="'/' === $route.fullPath ? 'active': ''">
                    <router-link to="/" class="d-flex align-items-center"
                                 :style="['/' === $route.fullPath ? {background: $env_color, boxShadow: '0 0 10px 1px ' + $env_color}: {}]">
                        <i data-feather="home"></i>
                        <span class="menu-title">{{$__('Dashboard')}}</span>
                    </router-link>
                </li>
                <li class="nav-item" :class="'/orders' === $route.fullPath ? 'active': ''">
                    <router-link to="/orders" class="d-flex align-items-center"
                                 :style="['/orders' === $route.fullPath ? {background: $env_color, boxShadow: '0 0 10px 1px ' + $env_color}: {}]">
                        <i data-feather="truck"></i>
                        <span class="menu-title">{{ $__('Orders') }}</span>
                    </router-link>
                </li>
                <li class="nav-item" :class="'/invoices' === $route.fullPath ? 'active': ''">
                    <router-link to="/invoices" class="d-flex align-items-center"
                                 :style="['/invoices' === $route.fullPath ? {background: $env_color, boxShadow: '0 0 10px 1px ' + $env_color}: {}]">
                        <i data-feather="file-text"></i>
                        <span class="menu-title">{{ $__('Invoices') }}</span>
                    </router-link>
                </li>
                <li class="nav-item" :class="'/reports' === $route.fullPath ? 'active': ''">
                    <router-link to="/reports" class="d-flex align-items-center"
                                 :style="['/reports' === $route.fullPath ? {background: $env_color, boxShadow: '0 0 10px 1px ' + $env_color}: {}]">
                        <i data-feather="pie-chart"></i>
                        <span class="menu-title">{{ $__('Reports') }}</span>
                    </router-link>
                </li>
                <li class="nav-item" :class="'/statistics' === $route.fullPath ? 'active': ''">
                    <router-link to="/statistics" class="d-flex align-items-center"
                                 :style="['/statistics' === $route.fullPath ? {background: $env_color, boxShadow: '0 0 10px 1px ' + $env_color}: {}]">
                        <i data-feather="bar-chart-2"></i>
                        <span class="menu-title">{{ $__('Statistics') }}</span>
                    </router-link>
                </li>
                <!--        TODO: removed for some period-->
                <!--        <li class="nav-item" id="main-menu-navigation-bottom">-->
                <!--          <router-link to="/" class="d-flex align-items-center">-->
                <!--            <i data-feather="settings"></i>-->
                <!--            <span class="menu-title">Settings &amp; more</span>-->
                <!--          </router-link>-->
                <!--        </li>-->
            </ul>
        </div>
    </div>
</template>

<script>
import {ref} from "vue";

const feather = require('feather-icons')
export default {
    name: "LeftNavbar",
    setup() {
        const collapsed = ref(false);
        const observer = ref();
        const logo_src = ref();
        return {collapsed, observer, logo_src}
    },
    mounted() {
        feather.replace()

        let self = this;
        this.$nextTick(() => {
            self.$loadScript('app-assets/js/core/app-menu.js').then(() => {
                self.$loadScript('app-assets/js/core/app.js')
            })
        })

        this.logo_src = require(`@/assets/images/${this.$logo}`);

        this.observer = new MutationObserver(mutations => {
            for (const m of mutations) {
                const newValue = m.target.getAttribute(m.attributeName);
                this.$nextTick(() => {
                    this.onClassChange(newValue, m.oldValue);
                });
            }
        });

        this.observer.observe(this.$refs.mainMenu, {
            attributes: true,
            attributeOldValue: true,
            attributeFilter: ['class'],
        });
    },
    beforeUnmount() {
        this.observer.disconnect();
        this.$unloadScript('app-assets/js/core/app-menu.js')
        this.$unloadScript('app-assets/js/core/app.js')
    },
    methods: {
        toggleMenu: function () {
            this.collapsed = !this.collapsed;
            this.$forceUpdate()
        },
        onClassChange(classAttrValue) {
            const classList = classAttrValue.split(' ');
            if (classList.includes('expanded')) {
                this.logo_src = require(`@/assets/images/${this.$logo}`);
            } else {
                this.logo_src = require(`@/assets/images/${this.$logo_small}`);
            }
            this.$forceUpdate()
        }
    }
}
</script>

<style scoped>
</style>