<template>
    <div class="nav-item dropdown dropdown-language" id="set-language" style="position: absolute; top: 15px; right: 0;">
        <a class="nav-link dropdown-toggle" id="dropdown-flag" href="javascript:void(0);"
           data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false">
            <i :class="'flag-icon flag-icon-' + authUserLang.code"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-flag">
            <a class="dropdown-item" v-for="lang in available_langs" :key="lang.code"
               @click="setUserLang(lang)"><i :class="'flag-icon flag-icon-' + lang.code"></i>
                {{ lang.name }}</a>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
    name: "langChooser",
    data(){
        return {}
    },
    components: {},
    computed: {
        ...mapState({
            user: state => state.auth.user,
            available_langs: state => state.auth.available_langs,
            authUserLang: state => state.auth.user.lang
        }),
    },
    methods: {
        ...mapActions({
            setUserLang: 'auth/setUserLang'
        }),
    },
}
</script>

<style scoped>

</style>