<template>
    <div class="row">
        <div class="col-2 offset-10">
            <input class="form-control" :placeholder="$__('Search')" type="text" v-model="search" />
        </div>
    </div>
    <div class="row" v-cloak v-for="(relation, index) in relations" :key="relation.id">
        <div class="col-12">
            <blockquote class="blockquote" v-if="relation.billing_address">
                <p class="mb-0">{{ relation.name }} {{relation.billing_address.street_name ? ' - ': ''}} {{relation.billing_address.street_name}} {{relation.billing_address.house_number}}{{relation.billing_address.house_number_addition}} {{relation.billing_address.postal_code || relation.billing_address.place ? ' - ': ''}} {{relation.billing_address.postal_code}} {{relation.billing_address.place}}</p>
            </blockquote>
            <blockquote class="blockquote" v-else>
                <p class="mb-0">{{ relation.name }}</p>
            </blockquote>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-3 col-sm-6 col-12 d-flex align-content-stretch">
            <AccountManagerCard v-model="relations[index]" :key="relation.id"></AccountManagerCard>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-3 col-sm-6 col-12 d-flex align-content-stretch">
            <CreateOrderCard v-model="relations[index]" :key="relation.id"></CreateOrderCard>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-3 col-sm-6 col-12 d-flex align-content-stretch">
          <LastOrdersCard v-model="relations[index]" :key="relation.id"></LastOrdersCard>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-3 col-sm-6 col-12 d-flex align-content-stretch">
          <LastInvoicesCard v-model="relations[index]" :key="relation.id"></LastInvoicesCard>
        </div>
    </div>
</template>

<script>
import LastOrdersCard from "./collection_orders/LastOrdersCard";
import CreateOrderCard from "./collection_orders/CreateOrderCard";
import LastInvoicesCard from "./invoices/LastInvoicesCard";
import AccountManagerCard from "./crm/AccountManagerCard";
import {mapState} from "vuex";
import {ref} from "vue";

export default {
    name: "Home",
    setup() {
        const search = ref();
        const relations = ref([]);
        return {search, relations}
    },
    components: {
        LastOrdersCard,
        LastInvoicesCard,
        AccountManagerCard,
        CreateOrderCard,
    },
    watch: {
        selectedRelations: {
            handler: function (newVal) {
                this.search = ''
                this.relations = newVal
            }
        },
        'search': {
            handler: function (newVal) {
                if(newVal){
                    let filteredList = []
                    this.relations.forEach(r => {
                        if(r.id.toString().includes(newVal)){
                            filteredList.push(r)
                        } else if(r.name.toLowerCase().includes(newVal.toLowerCase())){
                            filteredList.push(r)
                        } else if(r.billing_address && r.billing_address.place && r.billing_address.place.toLowerCase().includes(newVal.toLowerCase())){
                            filteredList.push(r)
                        } else if(r.billing_address && r.billing_address.street_name && r.billing_address.street_name.toLowerCase().includes(newVal.toLowerCase())){
                            filteredList.push(r)
                        }
                    })
                    this.relations = filteredList
                } else {
                    this.relations = this.selectedRelations
                }
            }
        }
    },
    mounted() {
        this.relations = this.selectedRelations
    },
    computed: {
        ...mapState({
            selectedRelations: state => state.orders.selectedRelations,
        }),
    },
    beforeUnmount() {
    }
}
</script>

<style scoped>

</style>