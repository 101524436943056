import axios from 'axios';
import store from "@/store";
import AuthService from '@/services/auth.service';

const API_URL = `${process.env.VUE_APP_ENV_API || 'http://127.0.0.1:8000/api/'}`;

axios.interceptors.request.use(
    config => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) config.headers.Authorization = `Token ${token.access}`;
        config.headers['Content-Language'] = store.state.auth.user.lang.code;
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

axios.interceptors.response.use(response => response, async error => {
    const originalRequest = error.config;

    if (error && !error.response){
        store.dispatch("auth/logout");
        return Promise.reject(error)
    }
    if (error.response.status === 401 && originalRequest.url === API_URL + 'token/refresh/') {
        store.dispatch("auth/logout");
        return Promise.reject(error)
    }

    if ((error.response.status === 404) && originalRequest.url.includes('customer/')) {
        store.dispatch("auth/logout");
        return Promise.reject(error)
    }

    const token = JSON.parse(localStorage.getItem('token'));
    if (token){
        const { access, refresh } = token;
        if (access && refresh && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            return AuthService.refresh(access, refresh).then(response => {
                if (response.status === 200) {
                    localStorage.setItem('token', JSON.stringify({...token, access : response.data.access}));
                    axios.defaults.headers.common.Authorization = `Token ${response.data.access}`;
                }
                return axios(originalRequest);
            }).catch(error => {
                return Promise.reject(error)
            });
        }
    }

    if (error.response.status === 401) {
        store.dispatch("auth/logout");
        return Promise.reject(error)
    }
    return Promise.reject(error);
})

const UserService = {
    getCurrentUser() {
        return axios.get(API_URL + 'portal/customer/');
    },
    activateUser(data) {
        return axios.post(API_URL + 'portal/customer/activate/', data);
    },
    getUserInfo(email) {
        return axios.get(API_URL + `portal/customer/${email}/`);
    },
}
const Orders = {
    last(relation_id) {
        return axios.get(API_URL + `portal/orders/last/?relation_id=${relation_id}`);
    },
    all(relation_id, params) {
        return axios.get(API_URL + `portal/orders/all/?relation_ids=${relation_id ? relation_id : ''}`, {params});
    },
    getWasteGroups(relation_id='') {
        return axios.get(API_URL + `portal/waste-groups/?relation_id=${relation_id}`);
    },
    getEuralCodes() {
        return axios.get(API_URL + `portal/eural-codes/`);
    },
    getEuralCodesByWasteGroup(waste_group_id, relation_id) {
        return axios.get(API_URL + `portal/eural-codes/${waste_group_id}/${relation_id}/`);
    },
    getCollectionHistory(waste_group_id, origin_id, eural_code_id) {
        return axios.get(API_URL + `portal/waste-groups/${waste_group_id}/history/?origin_id=${origin_id}&eural_code_id=${eural_code_id}`);
    },
    create(data) {
        return axios.post(API_URL + `portal/orders/create/`, data);
    },
    getChartData(relation_ids, waste_group, previous_year, current_year) {
        return axios.post(API_URL + `portal/orders/chart-data/`, {
            relation_ids: relation_ids,
            waste_group: waste_group, previous_year: previous_year, current_year: current_year
        });
    },
    getTotal(relation_ids, waste_group, previous_year, current_year) {
        return axios.post(API_URL + `portal/orders/total/`, {
            relation_ids: relation_ids,
            waste_group: waste_group, previous_year: previous_year, current_year: current_year
        });
    },
    getTransportLetter(order_id, order_type) {
        return axios.get(API_URL + `portal/orders/${order_type}/${order_id}/transport_letter/`, {responseType: 'blob'});
    }
}

const Relation = {
    get(relation_id) {
        return axios.get(API_URL + `portal/relation/${relation_id}/`);
    },
    getEuralCodesByRelation(relation_ids) {
        return axios.get(API_URL + `portal/eural-codes/?relation_ids=${relation_ids}`);
    },
    getCustomerReference(data) {
        let query_params = `debtor_id=${data.debtor_id}&disposer_id=${data.disposer_id}&waste_group_id=${data.waste_group_id}&origin_id=${data.origin_id}&eural_code_id=${data.eural_code_id}`
        return axios.get(API_URL + `portal/relation/customer_reference/?${query_params}`)
    }
}
const Report = {
    send_email(data) {
        return axios.post(API_URL + `portal/report/send-email/`, data);
    },
    download(data) {
        return axios.post(API_URL + `portal/report/download/`, data, {responseType: 'blob'});
    }
}

const Invoice = {
    last(relation_id) {
        return axios.get(API_URL + `portal/invoices/last/?relation_id=${relation_id ? relation_id : ''}`);
    },
    all(relation_id, params) {
        return axios.get(API_URL + `portal/invoices/all/?relation_ids=${relation_id ? relation_id : ''}`, {params});
    },
    getPDF(invoice_id) {
        return axios.get(API_URL + `portal/invoices/${invoice_id}/pdf/`, {responseType: 'blob'});
    }
}

export default {
    Axios: axios,
    UserService: UserService,
    Orders: Orders,
    Relation: Relation,
    Report: Report,
    Invoice: Invoice
};