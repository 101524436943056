<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <a href="" class="brand-logo">
                <img id="logo_img" v-cloak/>
            </a>
            <div class="card mb-0">
                <div class="card-body">
                    <h4 class="card-title mb-1 text-center">{{ $__('Welcome to our customer portal') }}</h4>
                    <p class="card-text mb-2 text-center">{{ $__('Please sign in below to access your data.') }}</p>
                    <lang-chooser></lang-chooser>

                    <Form @submit="handleLogin" :validation-schema="schema" class="auth-login-form mt-2" method="POST">
                        <div class="form-group">
                            <label for="login-email" class="form-label">{{ $__('Email') }}</label>
                            <Field type="text" class="form-control" id="login-email" @focus="focus" @blur="blur"
                                   name="email" placeholder="john@example.com" aria-describedby="login-email"
                                   tabindex="1" autofocus/>
                            <ErrorMessage name="email" class="error-feedback"/>
                        </div>

                        <div class="form-group">
                            <div class="d-flex justify-content-between">
                                <label for="login-password">{{ $__('Password') }}</label>
                                <router-link to="/password-reset" :style="{color: $env_color}">
                                    <small>{{ $__('Forgot Password?') }}</small>
                                </router-link>
                            </div>
                            <div class="input-group input-group-merge form-password-toggle">
                                <Field :type="passwordShowen ? 'text': 'password'" @focus="focus_group"
                                       @blur="blur_group" class="form-control form-control-merge" id="login-password"
                                       name="password" tabindex="2"
                                       placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                       aria-describedby="login-password"/>
                                <div class="input-group-append" @click="showPassword">
                                <span class="input-group-text cursor-pointer" :data-visible="passwordShowen"
                                      v-show="passwordShowen">
                                    <i data-feather="eye-off"></i>
                                </span>
                                    <span class="input-group-text cursor-pointer" :data-visible="!passwordShowen"
                                          v-show="!passwordShowen">
                                    <i data-feather="eye"></i>
                                </span>
                                </div>
                            </div>
                            <ErrorMessage name="password" class="error-feedback"/>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block" tabindex="4" :disabled="loading"
                                :style="{background: $env_color, borderColor: $env_color}">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span> {{ $__('Sign in') }}
                        </button>
                        <div class="form-group">
                            <div v-if="message" class="alert alert-danger" role="alert">
                                {{ message }}
                            </div>
                        </div>
                    </form>

                    <p class="text-center mt-2">
                        <span>{{ $__('New on our portal?') }} </span>
                        <router-link to="/register">
                            <span :style="{color: $env_color}">{{ $__('Create an account') }}</span>
                        </router-link>
                    </p>
                </div>
            </div>
            <!-- /Login v1 -->
        </div>
    </div>
</template>

<script>
import {Form, Field, ErrorMessage} from "vee-validate";
import * as yup from "yup";
import {envColor} from "../mixins";
import langChooser from "./utils/langChooser.vue";

const feather = require('feather-icons')

export default {
    name: "Login",
    mixins: [envColor],
    components: {
        Form,
        Field,
        ErrorMessage,
        langChooser
    },
    data() {
        const schema = yup.object().shape({
            email: yup.string().email().required("Email is required!").strict().lowercase(),
            password: yup.string().min(6).required("Password is required!"),
        });

        return {
            loading: false,
            message: "",
            schema,
            passwordShowen: false
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    created() {
        if (this.loggedIn) {
            this.$router.push("/");
        }
    },
    mounted() {
        feather.replace()
        document.body.classList.add('blank-page');
        document.body.classList.add('login-page');
        const pathToBg = require(`@/assets/images/${this.$background_image}`)
        const pathToLogo = require(`@/assets/images/${this.$logo}`);
        document.body.style.backgroundImage = `url('${pathToBg}')`;
        let logo_img = document.getElementById('logo_img')
        logo_img.style.height = `100px`;
        logo_img.src = `${pathToLogo}`;
        let footer = document.getElementById('footer')
        footer.classList.add('footer_login')
    },
    unmounted() {
        document.body.classList.remove('blank-page');
        document.body.classList.remove('login-page');
        document.body.style.backgroundImage = 'none';
        let footer = document.getElementById('footer')
        footer.classList.remove('footer_login')
    },
    methods: {
        handleLogin(user) {
            this.loading = true;
            this.$store.dispatch("auth/login", user).then(
                async () => {
                    await this.$store.dispatch("auth/setUser");
                    this.$router.push("/");
                },
                (error) => {
                    this.loading = false;
                    let message = ''
                    if(error.response && error.response.data && error.response.data.message){
                        message = error.response.data.message
                    } else if (error.response && error.response.data && error.response.data.detail){
                        message = error.response.data.detail
                    } else {
                        message = error.message || error.toString()
                    }
                    this.message = message;
                }
            );
        },
        showPassword() {
            this.passwordShowen = !this.passwordShowen
        }
    },
};
</script>

<style scoped>
</style>